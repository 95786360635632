<template>
  <v-autocomplete
    :value="value"
    :items="clinicians"
    :loading="isLoading"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-no-data
    hide-selected
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    outlined
    item-text="name"
    :label="label"
    placeholder="Start typing to Search"
    clearable
    return-object
    @update:search-input="fetchClinicians"
    @change="handleFieldChange"
    @click:clear="$emit('change', null)"
    @blur="$emit('blur')"
  />
</template>

<script>
import { debounce } from "lodash";
import { getClinicians } from "../../services/users";

export default {
  name: "CliniciansSelectSearch",

  props: {
    value: {
      type: [String, Object],
      default: () => null,
    },
    roles: {
      type: Array,
      default: () => ["CLINICIAN"],
    },
    label: {
      type: String,
      default: "Clinician Name",
    },
    itemValue: {
      type: String,
      default: () => "",
    },
    hideDetails: {
      type: Boolean,
      default: () => true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      clinicians: [],
    };
  },

  methods: {
    handleFieldChange(value) {
      this.$emit(
        "change",
        this.itemValue && value ? value[this.itemValue] : value
      );
    },
    fetchClinicians: debounce(async function (value) {
      try {
        if (!value?.trim()) return this.clinicians = [];
        this.isLoading = true;
        const select = "name";
        const filters = {
          name: { $regex: value, $options: "i" },
        };
        const { data } = await getClinicians({
          select,
          filter: filters,
          pageSize: 50,
        });
        this.clinicians = data;
        this.loading = false;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.isLoading = false;
      }
    }, 1500),
  },
};
</script>
