<template>
  <v-main class="clinicians-page">
    <div class="app-container d-flex flex-column align-center pt-10 pb-3">
      <section
        class="clinicians-page__tool-bar d-flex flex-row justify-space-between align-center w-100 mb-5"
      >
        <div class="clinicians-page__tool-bar__info">
          <h1 class="text-h4 font-weight-bold">
            {{ pageInfo.title }}
          </h1>
        </div>

        <div class="d-flex align-center">
          <a
            v-if="!isMobile"
            class="clinicians-page__tool-bar-filter-btn d-flex align-center justify-center"
            :class="{
              'clinicians-page__tool-bar-filter-btn--active': isFilterActive,
            }"
            @click="handleFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="clinicians-page__tool-bar-filter-btn d-flex align-center justify-center"
              @click="handleFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>

          <v-btn
            :disabled="!clinicians.length"
            :loading="exportLoading"
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="generateCliniciansTable"
          >
            <v-icon>mdi-download</v-icon>
            Export
          </v-btn>
        </div>
      </section>

      <v-skeleton-loader
        v-if="firstLoading"
        :loading="loading"
        min-width="100%"
        type="table"
      />

      <section v-show="!firstLoading" class="clinicians-page__table">
        <v-data-table
          :headers="tableHeaders"
          :loading="loading"
          :items="clinicians"
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="totalClinicians"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          multi-sort
          item-key="_id"
          @update:options="initialize"
        >
          <template v-if="isFilterActive && !isMobile" v-slot:top>
            <v-row class="d-flex align-center mb-3">
              <v-col class="d-flex" cols="4">
                <CliniciansSelectSearch
                  :values="filters.clinicianId"
                  :label="'Clinician'"
                  :item-value="'_id'"
                  @change="handleFilterChange($event, 'clinicianId')"
                />
              </v-col>
              <v-col md="4">
                <v-select
                  :value="filterManagerName"
                  :items="managerOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Manager"
                  hide-details
                  clearable
                  outlined
                  @change="handleFilterChange($event, 'managerId')"
                />
              </v-col>
              <v-col md="4">
                <ClinicianStatusesSelect
                  :status="filters.category"
                  label="Clinician category"
                  @update="handleFilterChange($event, 'category')"
                />
              </v-col>
              <v-col md="4">
                <v-select
                  :value="filters.position"
                  :items="positionOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Position"
                  hide-details
                  clearable
                  outlined
                  @change="handleFilterChange($event, 'position')"
                />
              </v-col>
              <v-col md="3">
                <TimeDataPicker
                  ref-name="menu1"
                  :date="filters.caseDate"
                  label="Case Date"
                  @change="handleFilterChange($event, 'caseDate')"
                />
              </v-col>
              <v-col class="d-flex justify-center ml-12" cols="1">
                <CancelButton @click="handleFilterMenuCancel" />
              </v-col>
              <v-col class="d-flex justify-center ml-16" cols="1">
                <ApplyButton @click="initialize" />
              </v-col>
            </v-row>
          </template>

          <template v-slot:item="{ item }">
            <tr @click="handleRowClick(item)">
              <td>
                <div class="d-flex flex-start align-center">
                  <CliniciansNameBadge
                    v-if="item.category"
                    :clinician-status="item.category"
                    class="mr-2"
                  />
                  <span>{{ item.name }}</span>
                </div>
              </td>
              <td style="min-width: 130px">
                {{ get(item, "position.name", "-") }}
              </td>
              <td style="min-width: 130px">
                {{ get(item, "manager.name", "-") }}
              </td>
              <td style="max-width: 120px">
                {{ get(item, "casesCount", 0) }}
              </td>
              <td style="min-width: 140px">
                {{ get(item, "reviewedCases", 0) }}
              </td>
              <td style="min-width: 110px">
                {{ get(item, "score", 0) }}
              </td>
              <td style="min-width: 125px">
                {{ get(item, `statistic.${CasesStatuses.compliant}`, 0) }}
              </td>
              <td style="min-width: 155px">
                {{
                  get(item, `statistic.${CasesStatuses.partiallyCompliant}`, 0)
                }}
              </td>
              <td style="min-width: 155px">
                {{ get(item, `statistic.${CasesStatuses.noncompliant}`, 0) }}
              </td>
              <td style="min-width: 120px">
                <span>{{ dateToLocalString(item.updatedAt, false) }}</span>
              </td>
            </tr>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>
        <v-pagination
          v-if="isMobile"
          v-model="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>

      <v-dialog v-if="isMobile" v-model="isFilterActive" fullscreen persistent>
        <v-card flat class="clinicians-page__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="clinicians-page__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>
              <CliniciansSelectSearch
                :values="filters.clinicianId"
                :label="'Clinician'"
                :item-value="'_id'"
                @change="handleFilterChange($event, 'clinicianId')"
              />
              <v-select
                :value="filterManagerName"
                :items="managerOptions"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Manager"
                hide-details
                clearable
                outlined
                @change="handleFilterChange($event, 'managerId')"
              />
              <br />
              <ClinicianStatusesSelect
                :status="filters.category"
                label="Clinician category"
                @update="handleFilterChange($event, 'category')"
              />
              <br />
              <v-select
                :value="positionName"
                :items="positionOptions"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Position"
                hide-details
                clearable
                outlined
                @change="handleFilterChange($event, 'position')"
              />
              <br />
              <TimeDataPicker
                ref-name="menu2"
                :date="filters.caseDate"
                label="Case Date"
                @change="handleFilterChange($event, 'caseDate')"
              />
            </div>

            <div
              class="clinicians-page__filter-menu__controls d-flex flex-column"
            >
              <ApplyButton large class="mb-5" @click="initialize" />
              <CancelButton large @click="onFilterCancel" />
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-main>
</template>

<script>
import { get } from "@/helpers/get";
import CliniciansNameBadge from "@/components/common/CliniciansNameBadge.vue";
import ClinicianStatusesSelect from "@/components/controls/ClinicianStatusesSelect.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import {
  getClinicians,
  getCliniciansTable,
  getManagers,
} from "@/services/users";
import dateToLocalString from "@/helpers/dateToLocalString";
import { createDownloadableLink } from "@/helpers/createDownloadableLink";
import {
  TableRowsPerPage,
  ClinicianStatuses,
  CasesStatuses,
} from "@/misc/constants";
import { getClinicianPositions } from "@/services/accountConfiguration";
import CliniciansSelectSearch from "@/components/controls/CliniciansSelectSearch.vue";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";

export default {
  name: "CliniciansPage",

  components: {
    CliniciansSelectSearch,
    CliniciansNameBadge,
    ClinicianStatusesSelect,
    TimeDataPicker,
    CancelButton,
    ApplyButton,
  },

  data() {
    const tableHeaders = [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Position",
        value: "position",
        sortable: false,
      },
      {
        text: "Manager",
        value: "managerName",
        sortable: false,
      },
      {
        text: "Cases",
        value: "casesCount",
        sortable: false,
      },
      {
        text: "Cases Reviewed",
        value: "reviewedCases",
        sortable: false,
        align: "start",
      },
      {
        text: "Score, %",
        value: "score",
        sortable: true,
        align: "start",
      },
      {
        text: `${CasesStatuses.compliant}, %`,
        value: "compliant",
        sortable: false,
      },
      {
        text: `${CasesStatuses.partiallyCompliant}, %`,
        value: "partiallyCompliant",
        sortable: false,
      },
      {
        text: `${CasesStatuses.noncompliant}, %`,
        value: "noncompliant",
        sortable: false,
      },
      {
        text: "Last Updated",
        value: "updatedAt",
        sortable: false,
      },
    ];
    const pageInfo = {
      title: "Clinicians",
      description: "Here you can change the statuses of clinicians.",
    };
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    return {
      options: {},
      totalClinicians: null,
      loading: false,
      pageInfo,
      tableHeaders,
      clinicians: [],
      isFilterActive: false,
      filters: {
        category: null,
        position: null,
        caseDate: null,
        managerId: null,
        clinicianId: null,
      },
      tableFooterOptions,
      firstLoading: true,
      positions: null,
      CasesStatuses,
      exportLoading: false,
      managers: null,
      filterManagerName: null,
      positionName: null,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isFilterButtonActive() {
      return Object.values(this.filters).some((val) => !!val === true);
    },
    filterBadgeContent() {
      return +!!this.search;
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalClinicians / this.options.itemsPerPage);
    },
    positionOptions() {
      if (!this.positions?.length) return [];
      return this.positions.map((p) => ({ text: p.name, value: p._id }));
    },
    managerOptions() {
      if (!this.managers?.length) return [];
      return this.managers.map((p) => ({ text: p.name, value: p._id }));
    },
  },

  beforeMount() {
    if (this.$route.query?.newClinicians) {
      this.filters.category = ClinicianStatuses.undefined;
      this.isFilterActive = true;
    }
  },

  async mounted() {
    await this.getTableOptions();
  },

  methods: {
    get,
    dateToLocalString,
    async getTableOptions() {
      try {
        const [managers, clinicianPositions] = await Promise.all([
          getManagers(),
          getClinicianPositions({}),
        ]);
        this.managers = managers || [];
        this.positions = clinicianPositions?.data || [];
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async initialize() {
      this.loading = true;
      try {
        const query = this.buildQuery();
        const { data, total } = await getClinicians({
          ...query,
          withStatistic: true,
        });
        this.clinicians = data;
        this.totalClinicians = total;
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }
      if (Object.values(this.filters).some((val) => !!val === true)) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "caseDate") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
        queryParams.filter = filters;
      }

      return queryParams;
    },
    handleFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    handleRowClick(item) {
      this.$router.push(`/clinicians/${item._id}`);
    },
    async handleFilterChange(data, key) {
      this.filters[key] = data;
      this.options.page = 1;
    },
    async handleFilterMenuCancel() {
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      await this.initialize();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    async generateCliniciansTable() {
      try {
        this.exportLoading = true;
        const query = this.buildQuery();
        const response = await getCliniciansTable(query);
        createDownloadableLink(
          response,
          `clinicians-${new Date().toISOString().slice(0, 10)}.csv`
        );
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Generate clinicians results",
          text: e?.response?.data?.message || JSON.stringify(e),
        });
      } finally {
        this.exportLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.clinicians-page {
  .v-data-table__wrapper {
    overflow: unset;

    @media (max-width: 1600px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__tool-bar {
    &-filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }
}
</style>
